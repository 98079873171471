import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AuthenticationGuard } from '@app/auth';
import { AppRoute } from '../generated/extended';
import { HasModuleGuard } from './auth/has-module.guard';
import { PublicIframeGuard } from '@app/auth/public-iframe.guard';
import { HasLicenseGuard } from './auth/has-license.guard';
import { LoginRequiredGuard } from '@app/auth/login-required.guard';
import { RealEstateResolverResolver } from '@app/core/services/realEstate/real-estate-resolver.resolver';

const routes: AppRoute[] = [
  {
    path: 'settings',
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
    data: { roles: 'ROLE_USER' },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'license-report',
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
    data: { roles: 'ROLE_ADMIN' },
    loadChildren: () => import('./license-report/license-report.module').then((m) => m.LicenseReportModule),
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'jobs',
    loadComponent: () => import('./jobs/jobs.component').then((m) => m.JobsComponent),
  },
  {
    path: 'demo',
    loadChildren: () => import('./book-a-demo/book-a-demo.module').then((m) => m.BookADemoModule),
  },
  {
    path: 'impressum',
    loadChildren: () => import('./impressum/impressum.module').then((m) => m.ImpressumModule),
  },
  {
    path: 'luucy-connect',
    loadChildren: () => import('./luucy/luucy.module').then((m) => m.LuucyModule),
  },
  {
    path: 'public-connect',
    // outlet: 'content',
    loadChildren: () => import('./public-api/public-api.module').then((m) => m.PublicApiModule),
  },
  {
    path: 'public-process-form',
    canLoad: [PublicIframeGuard, HasModuleGuard],
    canActivate: [PublicIframeGuard, AuthenticationGuard, HasModuleGuard],
    data: { roles: 'ROLE_USER', module: 'BUILDING_COSTS_AND_DATES' },
    loadChildren: () =>
      import('./calculators/new-building-costs/new-building-costs.module').then((m) => m.NewBuildingCostsModule),
  },
  {
    path: 'process-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'BUILDING_COSTS_AND_DATES' },
    loadChildren: () =>
      import('./calculators/new-building-costs/new-building-costs.module').then((m) => m.NewBuildingCostsModule),
  },
  {
    path: 'planning-foundation-form',
    canActivate: [LoginRequiredGuard, AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', modules: ['BUILDING_COSTS_AND_DATES', 'REBUILD_COSTS', 'OPERATING_COSTS', 'LCC'] },
    loadChildren: () =>
      import('./calculators/planning-foundation/planning-foundation.module').then((m) => m.PlanningFoundationModule),
  },
  {
    path: 'operational-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'OPERATING_COSTS' },
    loadChildren: () =>
      import('./calculators/operational-costs/operational-costs.module').then((m) => m.OperationalCostsModule),
  },
  {
    path: 'public-operational-form',
    canLoad: [PublicIframeGuard, HasModuleGuard],
    canActivate: [PublicIframeGuard, AuthenticationGuard, HasModuleGuard],
    data: { roles: 'ROLE_USER', module: 'OPERATING_COSTS' },
    loadChildren: () =>
      import('./calculators/operational-costs/operational-costs.module').then((m) => m.OperationalCostsModule),
  },
  {
    path: 'agv-small-building-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'SMALL_BUILDING_AGV' },
    loadChildren: () =>
      import('./calculators/small-building-agv-insurance/small-building-agv-insurance.module').then(
        (m) => m.SmallBuildingAgvInsuranceModule,
      ),
  },
  {
    path: 'public-rebuild-form',
    canLoad: [PublicIframeGuard, HasModuleGuard],
    canActivate: [PublicIframeGuard, AuthenticationGuard, HasModuleGuard],
    data: { roles: 'ROLE_USER', module: 'REBUILD_COSTS' },
    loadChildren: () => import('./calculators/rebuild-form/rebuild-form.module').then((m) => m.RebuildFormModule),
  },
  {
    path: 'rebuild-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'REBUILD_COSTS' },
    loadChildren: () => import('./calculators/rebuild-form/rebuild-form.module').then((m) => m.RebuildFormModule),
  },
  {
    path: 'public-lcc-form',
    canLoad: [PublicIframeGuard, HasModuleGuard],
    canActivate: [PublicIframeGuard, AuthenticationGuard, HasModuleGuard],
    data: { roles: 'ROLE_USER', module: 'LCC' },
    loadChildren: () => import('./calculators/lcc-form/lcc-form.module').then((m) => m.LccFormModule),
  },
  {
    path: 'lcc-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'LCC' },
    loadChildren: () => import('./calculators/lcc-form/lcc-form.module').then((m) => m.LccFormModule),
  },
  {
    path: 'public-lca-form',
    canLoad: [PublicIframeGuard, HasModuleGuard],
    canActivate: [PublicIframeGuard, AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'LCA' },
    loadChildren: () => import('./calculators/lca-form/lca-form.module').then((m) => m.LcaFormModule),
  },
  {
    path: 'lca-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'LCA' },
    loadChildren: () => import('./calculators/lca-form/lca-form.module').then((m) => m.LcaFormModule),
  },
  /*{
    path: 'effort-planner-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    data: { roles: 'ROLE_USER', module: 'EFFORT_PLANNER' },
    loadChildren: () => import('./calculators/effort-planner-form/effort-planner-form.module').then((m) => m.EffortPlannerFormModule),
  },*/

  {
    path: 'effort-planner-form',
    canActivate: [AuthenticationGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'EFFORT_PLANNER' },
    loadChildren: () => import('./calculators/effort-planner-form/ep-form.module').then((m) => m.EpFormModule),
  },

  {
    path: 'kv-to-bim/input',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard],
    data: { roles: 'ROLE_USER', module: 'BIM_EVALUATION' },
    resolve: { resolveData: RealEstateResolverResolver },
    loadComponent: () => import('./calculators/kv-to-bim/kv-to-bim.component').then((m) => m.KvToBimComponent),
  },
  {
    path: 'gvbs',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'INSURANCE_VALUES_GVBS' },
    loadChildren: () =>
      import('@app/calculators/insurance-costs-gvbs/insurance-costs-gvbs.module').then(
        (m) => m.InsuranceCostsGvbsModule,
      ),
  },
  {
    path: 'small-building-gvbs-form',
    canLoad: [HasModuleGuard],
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'SMALL_BUILDING_GVBS' },
    loadChildren: () =>
      import('./calculators/small-building-gvbs-form/small-building-gvbs.module').then(
        (m) => m.SmallBuildingGvbsModule,
      ),
  },
  {
    path: 'damage-form',
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'DAMAGE_COSTS' },
    loadChildren: () => import('./calculators/damage-form/damage-form.module').then((m) => m.DamageFormModule),
  },
  {
    path: 'public-insurance-form',
    canActivate: [PublicIframeGuard, AuthenticationGuard, HasModuleGuard],
    data: { roles: 'ROLE_USER', module: 'BUILDING_INSURANCE_VALUES' },
    loadChildren: () =>
      import('./calculators/insurance-costs/insurance-costs.module').then((m) => m.InsuranceCostsModule),
  },
  {
    path: 'insurance-form',
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'BUILDING_INSURANCE_VALUES' },
    loadChildren: () =>
      import('./calculators/insurance-costs/insurance-costs.module').then((m) => m.InsuranceCostsModule),
  },
  {
    path: 'agv',
    // outlet: 'content',
    canActivate: [AuthenticationGuard, HasModuleGuard],
    resolve: { HasLicenseGuard },
    data: { roles: 'ROLE_USER', module: 'SMALL_BUILDING_AGV' },
    loadChildren: () => import('./landing-page-agv/landing-page-agv.module').then((m) => m.LandingPageAgvModule),
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
  },
  {
    path: '',
    loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: '',
    loadChildren: () => import('../app/account/account.module').then((m) => m.AccountModule),
  },
  {
    path: '',
    loadChildren: () => import('./accuracy/accuracy.module').then((m) => m.AccuracyModule),
  },
  {
    path: '',
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
    data: { roles: 'ROLE_USER' },
    loadChildren: () => import('../app/realestates/realestates.module').then((m) => m.RealestatesModule),
  },
  {
    path: '',
    canLoad: [AuthenticationGuard],
    canActivate: [AuthenticationGuard],
    data: { roles: 'ROLE_ADMIN' },
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'insufficient-rights',
    canActivate: [AuthenticationGuard],
    loadChildren: () =>
      import('./insufficient_rights/insufficient-rights.module').then((m) => m.InsufficientRightsModule),
  },
  /**
   * this route needs to be last otherwise other routes might not work due to the param.
   */

  // {
  //   path: '',
  //   resolve: { resolveData: LanguageRoutingResolver },
  //   runGuardsAndResolvers: 'always',
  //   loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPageModule),
  // },
  // Fallback when no prior route is matched
  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      // enableTracing: !environment.production,
      //scrollPositionRestoration: 'enabled', // this is for scroll to top for entire app
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      //disabling routing tracers for now.
      enableTracing: false,
    }),
    TranslocoModule,
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
